<template>
  <div class="asider">
      <!-- <Breadcrumb></Breadcrumb> -->
      <el-container>
            <el-aside width="210px">
                    <!-- <MyHeader></MyHeader> -->
                    <Asider></Asider>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
  </el-container>
  </div>
</template>

<script>
// const Breadcrumb = ()=>import('components/common/Breadcrumb.vue')
// const MyHeader = ()=>import('components/my/Header.vue')
const Asider = ()=>import('components/my/Asider.vue')

  export default {
    name:"My",
    methods:{
     
  },
   created(){
     
     
    },
    mounted(){
     
    },
    components:{
        // Breadcrumb,
        // MyHeader,
        Asider
    }
  }
</script>
<style lang="less" scoped>
@import '~style/index.less';
.el-main {
    margin-left: 20px!important;
    padding:  20px!important;
    background: #fff;
    margin-bottom: 20px;
    margin-top: 20px;

}
 
</style>
